import * as CONST from '../../ActionTypes';
import * as util from '../index';

/**
 * ログイン
 */
export const loginCustomer = (
  param: { email: string; password: string; },
) => async (dispatch: any) => {
  dispatch(util.loading());
  try {
    const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/customers/signin_with_email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(param),
      mode: 'cors',
    });
    const payload = await response.json();
    if (payload.access_token && payload.refresh_token) {
      util.setTokenCustomer(payload.refresh_token);
      dispatch(util.requestSuccess(CONST.LOGIN_CUSTOMER_SUCCESS, payload));
      dispatch(util.unLoading());
    } else {
      dispatch(util.requestFailure(CONST.LOGIN_CUSTOMER_FAILURE, null));
      dispatch(util.unLoading());
    }
  } catch (error) {
    dispatch(util.requestFailure(CONST.LOGIN_CUSTOMER_FAILURE, error));
    dispatch(util.unLoading());
  }
};

/**
 * リフレッシュトークンでログイン
 */
export const refreshTokenCustomer = (param) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/customers/refresh_token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(param),
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((payload) => {
      if (payload.access_token && payload.refresh_token) {
        dispatch(util.requestSuccess(CONST.REFRESH_TOKEN_CUSTOMER_SUCCESS, payload));
        dispatch(util.unLoading());
        // デバイスにリフレッシュトークンを保存
        util.setTokenCustomer(payload.refresh_token);
      } else {
        dispatch(util.requestFailure(CONST.REFRESH_TOKEN_CUSTOMER_FAILURE, null));
        dispatch(util.unLoading());
      }
    })
    .catch((error) => {
      dispatch(util.requestFailure(CONST.REFRESH_TOKEN_CUSTOMER_FAILURE, error));
      dispatch(util.unLoading());
    });
};

/**
 * 登録
 */
export const register = (param) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/customers/send_register_mail`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(param),
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(util.requestSuccess(CONST.REGISTER_CUSTOMER_SUCCESS, null));
        dispatch(util.unLoading());
        return null;
      }
      return response.json();
    })
    .then((payload) => {
      if (payload?.detail === 'email already exists') {
        dispatch(util.requestFailure(CONST.REGISTER_CUSTOMER_FAILURE, 'すでに登録済みのメールアドレスです。'));
      } else {
        dispatch(util.requestFailure(CONST.REGISTER_CUSTOMER_FAILURE, payload?.detail));
      }
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.requestFailure(CONST.REGISTER_CUSTOMER_FAILURE, error));
      dispatch(util.unLoading());
    });
};

/**
 * 本会員登録
 */
export const registerProfile = (param) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/customers/signup_with_token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(param),
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(util.requestSuccess(CONST.REGISTER_CUSTOMER_SUCCESS, null));
        dispatch(util.unLoading());
        // return null;
      }
      return response.json();
    })
    .then((payload) => {
      console.log("payload");
      if (payload.access_token && payload.refresh_token) {
        dispatch(util.requestSuccess(CONST.REFRESH_TOKEN_CUSTOMER_SUCCESS, payload));
        dispatch(util.unLoading());
        // デバイスにリフレッシュトークンを保存
        util.setTokenCustomer(payload.refresh_token);
      }
      if (payload?.detail === 'email already exists') {
        dispatch(util.requestFailure(CONST.REGISTER_CUSTOMER_FAILURE, 'すでに登録済みのメールアドレスです。'));
      } else {
        dispatch(util.requestFailure(CONST.REGISTER_CUSTOMER_FAILURE, payload?.detail));
      }
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.requestFailure(CONST.REGISTER_CUSTOMER_FAILURE, error));
      dispatch(util.unLoading());
    });
};

/**
 * パスワード変更
 */
export const updatePassword = (accessToken, param) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/customers/update_password`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(param),
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(util.requestSuccess(CONST.CHANGE_PASSWORD_CUSTOMER_SUCCESS, null));
        return null;
      }
      return response.json();
    })
    .then((payload) => {
      let detail = 'エラーが発生しました。もうしばらくしてからお試ししていただき、問題が発生する場合はお問い合わせください。';
      if (payload?.status === 401) {
        detail = 'パスワードに誤りがあります。';
      } else if (payload?.detail === 'password and password confirmation does not match') {
        detail = '確認用パスワードに誤りがあります。';
      }
      if (payload) {
        dispatch(util.requestFailure(CONST.CHANGE_PASSWORD_CUSTOMER_FAILURE, detail));
      }
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.requestFailure(CONST.CHANGE_PASSWORD_CUSTOMER_FAILURE, error));
      dispatch(util.unLoading());
    });
};

/**
 * パスワードリセット送信
 */
export const setResetPassword = (param) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/customers/send_reset_password_mail`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(param),
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(util.requestSuccess(CONST.SEND_RESET_PASSWORD_CUSTOMER_SUCCESS, null));
        return null;
      }
      return response.json();
    })
    .then((payload) => {
      if (payload) {
        dispatch(util.requestFailure(CONST.SEND_RESET_PASSWORD_CUSTOMER_FAILURE, 'メールが存在していません。'));
      }
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.requestFailure(CONST.SEND_RESET_PASSWORD_CUSTOMER_FAILURE, error));
      dispatch(util.unLoading());
    });
};

/**
 * パスワードリセット
 */
export const resetPassword = (param) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/customers/reset_password`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(param),
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(util.requestSuccess(CONST.CHANGE_PASSWORD_CUSTOMER_SUCCESS, null));
        return null;
      }
      return response.json();
    })
    .then((payload) => {
      if (payload) {
        dispatch(util.requestFailure(CONST.CHANGE_PASSWORD_CUSTOMER_FAILURE, '確認パスワードが合ってません。'));
      }
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.requestFailure(CONST.CHANGE_PASSWORD_CUSTOMER_FAILURE, error));
      dispatch(util.unLoading());
    });
};

/**
 * ログアウト
 */
export const logoutCustomer = () => (dispatch) => {
  localStorage.removeItem('refresh_token_customer');
  dispatch(util.actionType(CONST.CUSTOMER_LOGOUT, null));
};

/**
 * 結果クリア
 */
export const clearResult = () => (dispatch) => {
  dispatch(util.actionType(CONST.CLEAR_RESULT_CUSTOMER, null));
};
